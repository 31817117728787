















































































































































import { SfRadio } from '@storefront-ui/vue';
import { ref, onMounted, defineComponent, computed } from '@nuxtjs/composition-api';
import usePaymentProvider from '~/modules/checkout/composables/usePaymentProvider';
import type { AvailablePaymentMethod } from '~/modules/GraphQL/types';
import useCartSelectedPaymentMethod from '~/modules/fortytwo/product/composables/useCartSelectedPaymentMethod';
import useCart from '~/modules/checkout/composables/useCart';
import SkeletonLoader from "~/components/SkeletonLoader/index.vue";

export default defineComponent({
  name: 'VsfPaymentProviderV2',
  components: {
    SfRadio,
    SkeletonLoader,
  },
  emits: ['status'],
  setup(_props, { emit }) {
    const paymentMethods = ref<AvailablePaymentMethod[]>([]);
    const { load, save } = usePaymentProvider();
    const selectedPaymentMethodCode = ref<string | null>(null);
    const { getCartSelectedPaymentMethod } = useCartSelectedPaymentMethod();
    const { cart, load: loadCart } = useCart();
    const isLoading = computed(()=> paymentMethods.value.length > 0 ? false : true);

    const fetchCartInfo = async (cart_id) => {
      const { data } = await getCartSelectedPaymentMethod(cart_id);
      selectedPaymentMethodCode.value = data.cart?.selected_payment_method?.code ?? "";
      emit('status', data.cart?.selected_payment_method?.code ?? "");
    };

    onMounted(async () => {
      if (!cart.value.id) {
          await loadCart();
      }
      await Promise.all([fetchCartInfo(cart.value.id)]);
      paymentMethods.value = await load();
    });

    const definePaymentMethods = async (paymentMethodCode: string) => {
      paymentMethods.value = await save({
        paymentMethod: {
          code: paymentMethodCode,
        },
      });

      selectedPaymentMethodCode.value = paymentMethodCode;

      emit('status', paymentMethodCode);
      emit('changePayment');
    };

    return {
      paymentMethods,
      selectedPaymentMethodCode,
      definePaymentMethods,
      isLoading,
    };
  },
});
